import React, { useState } from 'react'
import { Heading, VStack, ScaleFade, Spinner, Text } from '@chakra-ui/react'
import { withRouter } from 'react-router-dom'

const Start = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)

  setTimeout(() => setDoneLoading(true), 2000)

  return (
    <VStack spacing={8}>
      {doneLoading ? null : <Spinner size="xl" />}
      <ScaleFade in={doneLoading}>
        <Heading fontSize="3xl" lineHeight="tall">
          ❤️✨Love✨ mode activated ❤️
        </Heading>
        <Text>Courtesy of: M 🥺</Text>
      </ScaleFade>
    </VStack>
  )
}

export default withRouter(Start)
